.short_bg {
  /* background-image: url("../../Assets/Images/short_bg_pc.png"); */
  background-color: #212121;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.short {
  display: flex;
  box-sizing: border-box;
  padding-top: 80px;
  padding-bottom: 100px;
  width: 100%;
  height: auto;
}

.short_left {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  padding-right: 30px;
  width: 50%;
  position: relative;
}

.short::before {
  content: '';
  width: 2px;
  background-color: white;
  height: 100%;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}

.short_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.short_heading {
  color: #FFF;
  text-align: center;
  font-family: Manrope;
  font-size: 39px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 30px;
}

ul {
  list-style: none;
}

li {
  padding-left: 40px;
  background-image: url("../../Assets/Icons/li_style.svg");
  background-repeat: no-repeat;
  background-position: left center;
}

li {
  color: #FFF;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 30px;
}

@media (max-width: 1024px) {
  .short {
    display: flex;
    box-sizing: border-box;
    padding-top: 70px;
    padding-bottom: 20px;
    width: 100%;
    height: auto;
  }
  .short_bg {
    /* background-image: url("../../Assets/Images/planshet_bg.webp"); */
  }
  .short_left {
    padding-right: 30px;
  }
  .short_right {
    padding-right: 30px;
  }
  .short_heading {
    font-size: 21px;
    padding-left: 28px;
    margin-bottom: 20px;
  }
  li {
    padding-left: 25px;
    background-image: url("../../Assets/Icons/li_planshet.svg");
    background-repeat: no-repeat;
    background-position: left center;
  }
  li {
    color: #FFF;
    font-family: Manrope;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 15px;
  }
}

@media (max-width: 576px) {
  .short_bg {
    /* background-image: url("../../Assets/Images/short_bg_mob.png"); */
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }
  .short {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 70px;
    padding-bottom: 60px;
    width: 100%;
    height: auto;
    position: relative;
  }
  .short::before {
    content: '';
    width: 100%;
    background-color: white;
    height: 2px;
    position: absolute;
    bottom: 50%;
    left: 0;
    transform: translate(-5%, -50%);
  }
  .short_heading {
    color: #FFF;
    text-align: center;
    font-family: Manrope;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding-left: 28px;
    margin-bottom: 29px;
  }
  .short_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
  }
  .short_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    margin-top: 110px;
  }
  ul {
    padding-left: 28px;
  }
  li {
    padding-left: 25px;
    background-image: url("../../Assets/Icons/li_mob.svg");
    background-repeat: no-repeat;
    background-position: left center;
  }
  li {
    color: #FFF;
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 19px;
  }
}


