.contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.contact-name {
  color: #FFF;
  font-family: Manrope, sans-serif;
  font-size: 23.071px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 23px;
}
.contact-text {
  color: #FFF;
  font-family: Manrope, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 43px;
  white-space: nowrap;
}
.contact-phone, .contact-email {
  color: #FFF;
  font-family: Manrope, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}
.contact-phone {
  margin-bottom: 8px;
}

.contact:first-child {
  margin-right: 43px;
}

@media (max-width: 1024px) {
  .contact-name {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin-top: 13px;
  }
  .contact-text {
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 23px;
  }
  .contact-phone, .contact-email {
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
  }
  .contact-phone {
    margin-bottom: 5px;
  }
}
@media (max-width: 576px) {
  .contact-name {
    font-size: 19.951px;
    font-weight: 600;
  }
  .contact-text {
    width: 80%;
    font-size: 13.301px;
    font-weight: 400;
    margin-bottom: 39px;
    white-space: normal;
  }
  .contact-phone, .contact-email {
    font-size: 14.963px;
    font-weight: 500;
    margin-bottom: 7px;
  }
  .contact-email {
    margin-bottom: 20px;
  }
}


