.hat
  display: flex
  flex-direction: column
  justify-content: center


  background-image: url('../../../src/Assets/Images/hat_main.png')
  background-repeat: no-repeat
  background-size: cover
  width: 100%
  height: 95vh

.hat_content
  display: flex
  flex-direction: column
  align-items: center
  box-sizing: border-box
  //padding-top: 155px
  //padding-bottom: 120px
  
.hat_logo
  padding-top: 40px
  width: 634px
  height: 330px

.hat_paragraph
  color: #FFF
  text-align: center
  font-family: Manrope
  font-size: 20px
  font-style: normal
  font-weight: 300
  line-height: normal


@media (max-width: 1024px)
  .hat
    height: 50vh

  .hat_logo
    padding-top: 40px
    width: 346px
    height: 180px

  .hat_paragraph
    font-size: 11px



@media (max-width: 576px)

  .hat
    height: 70vh

  .hat_content
    display: flex
    flex-direction: column
    align-items: center
    box-sizing: border-box

  .hat_logo
    width: 320px
    height: 200px

  .hat_paragraph
    color: #FFF
    text-align: center
    font-family: Manrope
    font-size: 12px
    font-style: normal
    font-weight: 300
    line-height: normal
    
