@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
.clients {
  margin-bottom: 170px;
  &__title {
    font-family: 'Manrope', sans-serif;
    font-size: 55px;
    font-weight: 500;
    line-height: 75px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 90px;

    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
  }

  padding: 0 100px;

  &__logos {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 56px;
    justify-content: center;
    align-items: center;
  }
  
  &__logo {
    justify-self: center;
  }
}

@media (max-width: 1100px) {
  .clients {
    &__title {
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
    }
  
    padding: 0 150px;
  
    &__logos {
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;
      justify-content: center;
      align-items: center;
    }
    
    &__logo {
      width: 80%;
      justify-self: center;
      align-self: center;
    }
  }
}

@media (max-width: 770px) {
  .clients {
    margin-bottom: 100px;
    &__title {
      font-size: 30px;
      line-height: 41px;
      margin-bottom: 40px;
      font-weight: 600;
    }
  }
}

@media (max-width: 992px) {
  .clients {
    &__title {
      // font-size: 30px;
      // line-height: 41px;
      // margin-bottom: 40px;
  
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
    }
  
    padding: 80px 0px;
  
    &__logos {
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;
      justify-content: center;
      align-items: center;
    }
    
    &__logo {
      width: 80%;
      justify-self: center;
      align-self: center;
    }
  }
}

@media (max-width: 550px) {
  .clients {
    &__title {
      font-size: 30px;
      font-weight: 600;
      line-height: 41px;
      justify-content: center;
    }
    margin-bottom: 0px;
  
    padding: 70px 50px;
  
    &__logos {
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 25px;
      justify-content: center;
      align-items: center;
    }
    
    &__logo {
      width: 80%;
      justify-self: center;
      align-self: center;
    }
  }
}
