.wrap {
  background: #2A2A2A;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-top: 1px;
  padding-bottom: 150px;
}
.wrap_firstBlock {
  margin-top: 150px;
}
.wrap_firstBlock > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.wrap_firstBlock__img {
  width: 400px;
  height: 536px;
  flex-shrink: 0;
}

.rightSide {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.rightSide_title {
  color: #FFF;
  font-family: "Manrope", sans-serif;
  font-size: 39px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 573px;
  margin-bottom: 27px;
}
.rightSide_firstDescription {
  color: #FFF;
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 618px;
  margin-bottom: 20px;
}
.rightSide_secondDescription {
  color: #FFF;
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 618px;
  margin-bottom: 43px;
}
.rightSide_btn {
  color: #FFF;
  background: inherit;
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border: 1px solid #FFF;
  display: flex;
  width: 294px;
  height: 53px;
  padding: 12.369px 126.434px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6.871px;
  flex-shrink: 0;
}

.infoText {
  color: #FFF;
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 100%;
  margin-bottom: 50px;
  margin-top: 50px;
}

@media (max-width: 1200px) {
  .wrap {
    padding-top: 62px;
    flex-wrap: wrap;
    padding-bottom: 0px;
  }
  .wrap_firstBlock {
    padding-bottom: 92px;
    margin-top: 0;
  }
  .wrap_firstBlock > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .wrap_firstBlock__img {
    width: 218px;
    height: 292px;
    flex-shrink: 0;
  }
  .rightSide {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .rightSide_title {
    color: #FFF;
    font-family: "Manrope", sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 313px;
    margin-bottom: 14px;
  }
  .rightSide_firstDescription {
    color: #FFF;
    font-family: "Manrope", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 337px;
    margin-bottom: 8px;
  }
  .rightSide_secondDescription {
    color: #FFF;
    font-family: "Manrope", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 337px;
    margin-bottom: 21px;
  }
  .rightSide_btn {
    color: #FFF;
    background: inherit;
    text-align: center;
    font-family: "Manrope", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: 1px solid #FFF;
    display: flex;
    width: 160px;
    height: 29px;
    padding: 6.746px 68.964px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.748px;
    flex-shrink: 0;
  }
  .infoText {
    font-size: 12px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .wrap {
    padding-top: 35px;
    flex-wrap: wrap;
    padding-bottom: 0px;
  }
  .wrap_firstBlock {
    padding-bottom: 70px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .wrap_firstBlock > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .wrap_firstBlock > div:last-of-type {
    flex-direction: column-reverse;
  }
  .wrap_firstBlock__img {
    width: 358.362px;
    height: 203.828px;
    object-fit: cover;
    margin-bottom: 29px;
  }
  .wrap_firstBlock__img:last-of-type {
    margin-top: 30px;
  }
  .rightSide {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .rightSide_title {
    color: #FFF;
    font-family: "Manrope", sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 303px;
    margin-bottom: 18px;
  }
  .rightSide_firstDescription {
    color: #FFF;
    font-family: "Manrope", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 313px;
    margin-bottom: 11px;
  }
  .rightSide_secondDescription {
    color: #FFF;
    font-family: "Manrope", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 303px;
    margin-bottom: 31px;
  }
  .rightSide_btn {
    color: #FFF;
    background: inherit;
    text-align: center;
    font-family: "Manrope", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: 1px solid #FFF;
    display: flex;
    width: 160px;
    height: 29px;
    padding: 6.746px 68.964px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.748px;
    flex-shrink: 0;
  }
  .rightSide_btn:nth-of-type(2) {
    margin-bottom: 20px;
  }
  .infoText {
    font-size: 10px;
    width: 320px;
  }
}


