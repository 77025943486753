.contact
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  &-name
    color: #FFF
    font-family: Manrope, sans-serif
    font-size: 23.071px
    font-style: normal
    font-weight: 600
    line-height: normal
    margin-top: 23px
  &-text
    color: #FFF
    font-family: Manrope, sans-serif
    font-size: 15px
    font-style: normal
    font-weight: 400
    line-height: normal
    margin-bottom: 43px
    white-space: nowrap
  &-phone, &-email
    color: #FFF
    font-family: Manrope, sans-serif
    font-size: 17px
    font-style: normal
    font-weight: 500
    line-height: normal
    text-decoration: none
  &-phone
    margin-bottom: 8px
.contact:first-child
  margin-right: 43px
@media (max-width: 1024px)
  .contact
    &-name
      font-size: 12px
      font-style: normal
      font-weight: 600
      margin-top: 13px
    &-text
      font-size: 8px
      font-style: normal
      font-weight: 400
      margin-bottom: 23px
    &-phone, &-email
      font-size: 9px
      font-style: normal
      font-weight: 500
    &-phone
      margin-bottom: 5px
@media (max-width: 576px)
  .contact
    &-name
      font-size: 19.951px
      font-weight: 600
    &-text
      width: 80%
      font-size: 13.301px
      font-weight: 400
      margin-bottom: 39px
      white-space: normal
    &-phone, &-email
      font-size: 14.963px
      font-weight: 500
      margin-bottom: 7px
    &-email
      margin-bottom: 20px
