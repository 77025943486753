.footer {
  background: #2A2A2A;
}
.footer-wrapper {
  display: flex;
  justify-content: center;
}
.footer-title {
  color: #FFF;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 85px 0px 50px 0px;
}
.footer-form {
  width: 350px;
  border-top: 1px solid #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}
.footer-contact {
  display: flex;
  border-top: 1px solid #FFF;
  margin-bottom: 35px;
}
.footer-contacts {
  margin-left: 115px;
}
.footer-info__item {
  color: #FFF;
  font-family: Manrope, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 5px;
  white-space: nowrap;
}
.footer-info__item:last-child {
  margin-top: 24px;
}
.footer-img {
  width: 100%;
  height: 130px;
  margin-top: 130px;
  background-image: url("../../Assets/Images/footerWEB.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.send-form {
  width: 300px;
  height: 51px;
  justify-content: center;
  align-items: center;
  font-family: Manrope, sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  border: none;
}

input, textarea {
  width: 300px;
  height: 50px;
  background: transparent;
  border: 1px solid #FFF;
  color: #FFF;
  font-family: Manrope, sans-serif;
  font-size: 21px;
  font-weight: 300;
  margin: 10px auto;
  text-align: center;
}

textarea {
  height: 110px;
  text-align: left;
  padding: 10px 0 0 20px;
  font-size: 17.05px;
  font-style: normal;
  font-weight: 300;
}

input::placeholder {
  color: #FFF;
}

textarea::placeholder {
  color: #FFF;
}

@media (max-width: 1024px) {
  .footer-title {
    padding: 46px 0 28px 0;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }
  .footer-form {
    width: 180px;
    padding-top: 13px;
  }
  .footer-info__item {
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 3px;
  }
  .footer-info__item:last-child {
    margin-top: 13px;
  }
  .footer-contact {
    margin-bottom: 19px;
  }
  .footer-contacts {
    margin-left: 63px;
  }
  .footer-img {
    height: 72px;
    margin-top: 72px;
    background-image: url("../../Assets/Images/footerLaptop.webp");
    background-size: cover;
  }
  .send-form {
    width: 159.158px;
    height: 27.609px;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    margin-top: 5px;
  }
  input, textarea {
    width: 159.158px;
    height: 27.609px;
    font-size: 11px;
    font-weight: 300;
    margin: 5px 0;
  }
  textarea {
    height: 60px;
  }
}
@media (max-width: 576px) {
  .footer-title {
    font-size: 30px;
    font-weight: 600;
    padding: 70px 0 14px 0;
    margin: 0;
  }
  .footer-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .footer-form {
    border: none;
    padding-top: 0;
  }
  .footer-contact {
    border: none;
    flex-direction: column;
  }
  .footer-contacts {
    margin: auto;
    width: 90%;
    margin-top: 53px;
  }
  .footer-info {
    display: none;
  }
  .footer-img {
    height: 180px;
    margin-top: 40px;
    background-image: url("../../Assets/Images/footerMB.webp");
    background-size: cover;
  }
  .contact:last-child {
    border-top: 1px solid white;
    width: 80%;
  }
  input, textarea, .send-form {
    width: 305px;
    height: 45px;
    font-size: 17px;
    font-weight: 300;
    margin: 9px 0;
  }
  textarea {
    height: 97px;
  }
}


