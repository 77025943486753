

.aboutUs {
    font-family: 'Manrope', sans-serif;
    text-align: center;
    padding: 0 50px;
    position: relative;

    &__title {
        font-size: 55px;
        font-weight: 500;
        margin-bottom: 62px;
    }
    
    &__text {
        font-size: 24px;
        font-weight: 400;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: center;
    }

    margin-top: 170px;
    margin-bottom: 270px;
}

.bubble {
    background-color: #EFEFEF;
    position: absolute;
    border-radius: 50%;
    box-sizing: border-box;

    border: 10px #EFEFEF solid;

    overflow-x: hidden !important;

    &__1 {
        top: 0;
        left: 0;
        transform: translate(30%, -40%);
        animation: bounce_1 10s linear infinite;
    }

    &__2 {
        // FROM 3
        top: 0;
        right: 0;
        transform: translate(-40%, -50%) scale(1.1);
        animation: bounce_2 8s linear infinite;
    }

    &__3 {
        // FROM 7
        top: 100%;
        left: 50%;
        transform: translate(-160%, 30%) scale(1.1);
        animation: bounce_7 20s linear infinite;
    }

    &__4 {
        // FROM 6
        bottom: 0;
        left: 0;
        transform: translate(-70%, 30%) scale(0.8);
        animation: bounce_6 12s linear infinite;
    }

    &__5 {
        bottom: 0;
        right: 0;
        transform: translate(0%, 80%) scale(0.8);
        animation: bounce_5 20s linear infinite;
    }
}

@keyframes bounce_1 {
    0% {
        transform: translate(30%, -40%);
    }
    50% {
        transform: translate(30%, -90%);
    }
    100% {
        transform: translate(30%, -40%);
    }
}

@keyframes bounce_2 {
    0% {
        transform: translate(-40%, -50%) scale(1.1);
    }
    50% {
        transform: translate(-20%, -65%) scale(1.1);
    }
    100% {
        transform: translate(-40%, -50%) scale(1.1);
    }
}

@keyframes bounce_4 {
    0% {
        transform: translate(50%, 0%) scale(0.7);
    }
    50% {
        transform: translate(50%, 40%) scale(0.7);
    }
    100% {
        transform: translate(50%, 0%) scale(0.7);
    }
}

@keyframes bounce_5 {
    0% {
        transform: translate(0%, 80%) scale(0.8);
    }
    50% {
        transform: translate(-100%, 100%) scale(0.8);
    }
    100% {
        transform: translate(0%, 80%) scale(0.8);
    }
}

@keyframes bounce_6 {
    0% {
        transform: translate(-70%, 30%) scale(0.8);
    }
    50% {
        transform: translate(-70%, 0%) scale(0.8);
    }
    100% {
        transform: translate(-70%, 30%) scale(0.8);
    }
}

@keyframes bounce_7 {
    0% {
        transform: translate(-160%, 30%) scale(1.1);
    }
    50% {
        transform: translate(-100%, 0%) scale(1.1);
    }
    100% {
        transform: translate(-160%, 30%) scale(1.1);
    }
}

@media (max-width: 1100px) {

    .aboutUs {
        font-family: 'Manrope', sans-serif;
        text-align: center;
        padding: 50px 50px;
        position: relative;
        margin-top: 100px;
    }

    .bubble {
        animation: none;

        &__1 {
            transform: translate(50%, -10%) scale(0.8);
        }

        &__2 {
            transform: translate(-60%, -30%) scale(1.1);
        }

        &__3 {
            transform: translate(-160%, 10%) scale(1.1);
        }

        &__4 {
            transform: translate(20%, 60%) scale(0.8);
        }
    
        &__5 {
            transform: translate(-50%, 80%) scale(0.8);
        }
    }
}

@media (max-width: 990px) {
    
    .bubble {
        &__1 {
            transform: translate(-30%, 0%) scale(0.8);
        }

        &__2 {
            transform: translate(10%, -20%) scale(0.9);
        }

        &__3 {
            transform: translate(-200%, 0%) scale(0.9);
        }

        &__4 {
            transform: translate(-30%, -80%) scale(0.6);
        }
    
        &__5 {
            transform: translate(0%, 80%) scale(0.7);
        }
    }
}

@media (max-width: 768px) {
    .aboutUs {
    
        &__title {
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 33px;
        }
        
        &__text {
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
        }
        margin-top: 0px;
        padding-top: 80px;
        margin-bottom: 40px;
    }
    .bubble {
        &__1 {
            transform: translate(-10%, 0%) scale(0.5);
        }

        &__2 {
            transform: translate(10%, -4%) scale(0.6);
        }

        &__3 {
            transform: translate(-120%, -30%) scale(0.6);
        }

        &__4 {
            
            transform: translate(-40%, -20%) scale(0.4);
        }
    
        &__5 {
            
            transform: translate(0%, 50%) scale(0.5);
        }

    }
}

@media (max-width: 540px) {
    .bubble {
        &__1 {
            transform: translate(0%, 0%) scale(0.5);
        }

        &__2 {
            transform: translate(-10%, -15%) scale(0.4);
        }

        &__3 {
            transform: translate(-80%, -30%) scale(0.6);
        }

        &__4 {
            transform: translate(-20%, 30%) scale(0.4);
        }
    
        &__5 {
            transform: translate(-20%, 40%) scale(0.5);
        }
    }
}

@media (max-width: 460px) {
    .aboutUs {
        &__title {
            margin-bottom: 20px;
            line-height: 41px;
        }
        
        &__text {
            font-size: 15px;
            line-height: 20px;
        }
    }
    .bubble {
        &__1 {
            transform: translate(-20%, -5%) scale(0.4);
        }

        &__2 {
            transform: translate(0%, -10%) scale(0.5);
        }

        &__3 {
            transform: translate(-60%, -40%) scale(0.5);
        }

        &__4 {
            transform: translate(-20%, 40%) scale(0.4);
        }
    
        &__5 {
            transform: translate(0%, 40%) scale(0.5);
        }
    }
}

