.lid {
  display: flex;
  padding: 135px 0;
}

@media (max-width: 1024px) {
  .lid {
    padding: 70px 0;
  }
}
@media (max-width: 576px) {
  .lid {
    padding: 110px 0;
  }
}


