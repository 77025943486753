/*.site-navbar {*/
/*    border: none;*/
/*    -webkit-box-shadow: none;*/
/*    box-shadow: none;*/
/*    border-radius: 0px;*/
/*    margin-bottom: 0px;*/
/*    background: none;*/
/*    position: absolute;*/
/*    top: 30px;*/
/*    width: 100%;*/
/*    z-index: 200;*/
/*    background: #fff;*/
/*}*/

/*.site-navbar .navbar-brand {*/
/*    display: inline-block;*/
/*    border: 2px solid #fff;*/
/*    padding: 5px 20px;*/
/*    color: #fff;*/
/*    text-transform: uppercase;*/
/*    letter-spacing: .2em;*/
/*}*/

/*.site-navbar .navbar-brand:hover {*/
/*    color: #fff;*/
/*}*/

/*.site-navbar.bg-light {*/
/*    padding-top: 30px;*/
/*    background: inherit !important;*/
/*    border-bottom: solid 2px white;*/
/*    position: static;*/
/*}*/

/*.site-navbar.bg-light {*/
/*    background: transparent !important;*/
/*}*/

.site-navbar {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0px;
    margin-bottom: 0px;
    background: none;
    position: absolute;
    top: 20px;
    width: 100%;
    z-index: 200;
    background: #fff;
}

.site-navbar .navbar-brand {
    display: inline-block;
    border: 2px solid #fff;
    padding: 5px 20px;
    color: #fff;
    letter-spacing: .2em;
}

.site-navbar .navbar-brand:hover {
    color: #fff;
}

.site-navbar.bg-light {
    padding-top: 25px;
    background: transparent !important;
    border-bottom: solid 2px white;
}


@media (max-width: 991.98px) {
    .site-navbar.bg-light {
        background: linear-gradient(180deg, #212121 100%, rgba(33, 33, 33, 0.00) 100%) !important;
        top: 0;
    }

    .site-navbar.bg-light .navbar-brand {
        display: inline-block;
        font-size: 18px;
        border: 2px solid #000;
        padding: 2px 10px !important;
        color: #000;
    }
}

.site-navbar .navbar-nav > li > a {
    color: #fff !important;
    font-size: 20px;
    font-weight: 300;
    font-family: Manrope;
    letter-spacing: .1em;
    position: relative;
    letter-spacing: .2em;
}

@media (max-width: 991.98px) {
    .site-navbar .navbar-nav > li > a {
        color: rgba(0, 0, 0, 0.7) !important;
    }
}

.site-navbar .navbar-nav.active > a {
    color: #bac964 !important;
}

.site-navbar.scrolled {
    background: #fff;
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.09);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
}

.site-navbar.scrolled.bg-light {
    background: #fff !important;
}

.site-navbar.scrolled .navbar-brand {
    display: inline-block;
    font-size: 18px;
    border: 2px solid #000;
    padding: 2px 10px !important;
}

.site-navbar.scrolled.awake, .site-navbar.scrolled.sleep {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

.site-navbar.scrolled.awake {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    background: #fff !important;
}

.site-navbar.scrolled.sleep {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    background: #bac964 !important;
}

.site-navbar.scrolled .navbar-brand {
    color: #000;
}

.site-navbar.scrolled .dropdown > a:before {
    color: rgba(0, 0, 0, 0.4);
}

.site-navbar.scrolled .navbar-nav > li > a {
    color: rgba(0, 0, 0, 0.7) !important;
}

.site-navbar.scrolled .navbar-nav > li > a:hover {
    color: #bac964 !important;
}

.site-navbar.scrolled .navbar-nav > li > a.active {
    color: #bac964 !important;
}

.site-navbar.scrolled .navbar-nav > li.active > a {
    color: #bac964 !important;
}

li.without-icon {
    list-style-type: none;
    background-image: none;
}

.navbar-nav {
    background: inherit !important;
}

@media (max-width: 1024px) {

    .site-navbar.bg-light {
        padding-top: 10px;
        background: linear-gradient(180deg, #212121 100%, rgba(33, 33, 33, 0.00) 100%);
        border-bottom: solid 2px white;
        position: fixed;
    }

    .navbar-nav {
        color: white;
        background: linear-gradient(180deg, #212121 100%, rgba(33, 33, 33, 0.00) 100%);
    }

    .navbar-light .navbar-nav .nav-link {
        color: white !important;
    }

    .navbar-light .navbar-toggler-icon {
        background-image: url(../../Assets/Icons/burger.svg);
    }
}

