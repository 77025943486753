.liditem
  width: 100%
  margin: auto
  text-align: center
  &-img
    width: 250px
    height: auto
    margin-bottom: 20px
  &-name
    color: #212
    font-family: Manrope, sans-serif
    font-size: 39.167px
    font-style: normal
    font-weight: 500
    line-height: normal
    margin-bottom: 26px
  &-profession
    color: #212
    text-align: center
    font-family: Manrope, sans-serif
    font-size: 20px
    font-style: normal
    font-weight: 500
    line-height: normal
    margin-bottom: 40px
  &-text
    width: 50%
    margin: auto
    color: #4A4A4A
    text-align: center
    font-family: Manrope, sans-serif
    font-size: 20px
    font-style: normal
    font-weight: 400
    line-height: normal
.vertical-line
  width: 1px
  height: 226px
  background: #212
  margin: 0 10px
@media (max-width: 1024px)
  .liditem
    &-img
      width: 123px
      height: auto
      margin-bottom: 10px
    &-name
      font-size: 21px
      margin-bottom: 14px
    &-profession
      font-size: 11px
      margin-bottom: 22px
    &-text
      font-size: 11px
  .vertical-line
    height: 123px
@media (max-width: 576px)
  .lid
    flex-direction: column
  .liditem
    &-img
      width: 140px
      height: auto
      margin-bottom: 16px
    &-name
      font-size: 21px
      margin-bottom: 16px
    &-profession
      font-size: 11px
      margin-bottom: 26px
    &-text
      width: 70%
      font-size: 11px
      margin-bottom: 50px
  .vertical-line
      height: 1px
      width: 236px
      margin: auto auto 50px auto
